export const imgsrcToFormat = (url/*: string */, format/*: string */, allow/*:: ?: string */)/*: string */ => {
  if ( url ) {
    if ( !allow || !(new RegExp('\\/media\\/.+?\\/(?:'+allow+')\\/')).exec(url) ) {
      return url.replace(/(\/media\/.+?\/).+?\//, '$1'+format+'/');
    }
  }
  return url || '';
};




const fullLang = document.documentElement.lang;
export const lang = fullLang.substr(0,2).toLowerCase();

export const getLocalTexts = (i18n, defaultLang) => {
  let txt = i18n[fullLang];
  if ( !txt ) {
    txt = i18n[lang];
    if ( !txt ) {
      const fallbackLang = defaultLang || Object.keys(i18n)[0];
      txt = i18n[fallbackLang];
    }
  }
  return txt;
};




let _s;
let _scripts;
const _getScriptElm = (src) => {
  if ( typeof src === 'string' ) {
    !_s && (_s = document.createElement('script'));
    !_scripts && (_scripts = document.getElementsByTagName('script'));
    _s.src = src;
    src = _s.src;
    let scriptElm = [].filter.call(_scripts, (script) => script.src === src  &&  'isloaded' in script.dataset)[0];
    if ( !scriptElm ) {
      scriptElm = document.createElement('script');
      scriptElm.src = src;
      scriptElm.dataset.isloaded = '0';
      scriptElm.addEventListener('load', () => { scriptElm.dataset.isloaded = '1'; });
      document.head.appendChild(scriptElm);
    } else if ( scriptElm.dataset.isloaded === '1' ) {
      scriptElm = null;
    }
    return scriptElm;
  }
};

export const loadScript = (...args) => {
  let idx = 0;
  const loadNext = () => {
    if ( args.length > idx ) {
      const item = args[idx++];
      const scriptElm = _getScriptElm(item);
      if ( scriptElm ) {
        scriptElm.addEventListener('load', loadNext);
      } else {
        if ( typeof item === 'function' ) {
          item();
        }
        loadNext();
      }
    }
  };
  loadNext();
};


export const phoneHref = (phone) => 'tel:' + (phone||'').replace(/[-\s]/g, '');
