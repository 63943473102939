/** @jsx E */
/* eslint-disable react/no-unknown-property */

import 'qj/polyfills/Promise';

import q from 'qj/q';
import qq from 'qj/qq';
import domid from 'qj/domid';
import load from 'qj/load';
import E from 'qj/E';

import { getLocalTexts, loadScript, lang } from './utils';

import formatMonitor from './formatmonitor';


const initTopPanel = () => {
    const txt = getLocalTexts({
        en: {
            expandProjects: 'Expand Projects',
            minimizeProjects: 'Minimize Projects',
        },
        is: {
            expandProjects: 'Stækka verkefni',
            minimizeProjects: 'Minnka verkefni',
        },
        no: {
            expandProjects: 'Utvid prosjeker',
            minimizeProjects: 'Redusere prosjeker',
        },
    });

    const projecthighlights = q('.projecthighlights');
    if (projecthighlights) {
        const projects = qq('.item', projecthighlights);
        projects.forEach(function (elm) {
            const img = q('img, ins.image', elm);
            elm._imgSrc = img && (img.src || img.getAttribute('data-src'));
            elm._preloaded = {};
            // elm._ajaxed = false;
            // elm._imgFormat = undefined;
        });

        let firstFew = Math.ceil(Math.random() * projects.length);
        while (firstFew--) {
            const projElm = projects.shift();
            projElm.parentNode.appendChild(projElm);
            projects.push(projElm);
        }

        var activeProjects = projects.slice(0, 1);
        var heroFormats = {
            phone: 'hero-small',
            phablet: 'hero-small',
            tablet: 'hero-medium',
            netbook: 'hero-medium',
            wide: 'hero-large',
            huge: 'hero-massive',
        };
        var imgFormat = '';
        var updateProjectImages = function () {
            activeProjects.forEach(function (elm) {
                if (elm._imgSrc && imgFormat !== elm._imgFormat) {
                    elm._imgFormat = imgFormat;
                    var url = elm._imgSrc.replace(
                        /(\/media\/.+?\/).+?\//,
                        '$1' + imgFormat + '/'
                    );
                    elm.style.backgroundImage = 'url(' + url + ')';
                    if (!elm._preloaded[url]) {
                        elm._preloaded[url] = true;
                        var preloader = new Image();
                        preloader.src = url;
                    }
                    if (!elm._ajaxed) {
                        elm._ajaxed = true;
                        var fileName = url.split('/').pop();
                        load(
                            '/da/CleverCropService/mediaInfo?filename=' +
                            fileName
                        ).then((res) => {
                            var data = JSON.parse(res);
                            var fpx = data.fpx != null ? data.fpx : 50;
                            // FIXME: Remove this experimental focalPoint shifting when Eplica UI has been fixed
                            fpx =
                                50 +
                                Math.max(-50, Math.min(50, 1.25 * (fpx - 50)));
                            elm.style.backgroundPosition = fpx + '% 50%';
                        });
                    }
                }
            });
        };
        formatMonitor.subscribe(function (media) {
            imgFormat = heroFormats[media.is];
            updateProjectImages();
        });

        var listscrollerStarted = false;
        var startListScroller = function () {
            if (!listscrollerStarted && projects.length > 1) {
                const $ = window.jQuery;
                $(projecthighlights).listscroller({
                    item: '.item',
                    paging: true,
                    jumpPager: false,
                    animation: 'crossfade',
                    speed: 600,
                    // autoScrollDelay: 6000,
                    windowSize: 1,
                    stepSize: 1,

                    topClass: '',
                    bottomClass: '',
                    currentPageClass: '',
                    currentItemClass: '',
                    cursorItemClass: 'item--current',
                    pagingTopClass: '',
                    pagingBottomClass: '',

                    classPrefix: 'projecthighlights',
                    pagingTemplate:
                        /* '<div>' + */ '<div class="projecthighlights__steplinks"/></div>' /* + '</div>' */,
                    nextBtnTemplate:
                        '<button class="projecthighlights__steplink projecthighlights__steplink--next" />',
                    prevBtnTemplate:
                        '<button class="projecthighlights__steplink projecthighlights__steplink--prev" />',
                    // jumpTemplate:      '<li class="jump"/>',
                    // jumpLabelTemplate: '<strong/>',
                    // jumpWrapTemplate:  '<span/>',
                    // jumpBtnTemplate:   '<a href="#"/>',

                    // setFocus: false,

                    moveCallback: function (items, cfg) {
                        var idx = cfg.index;
                        // $.focusHere( items[idx] );
                        activeProjects = [].slice.call(
                            items,
                            Math.max(0, idx - 1),
                            idx + 2
                        );
                        updateProjectImages();
                    },
                });
            }
            listscrollerStarted = true;
        };

        var isExpanded = false;
        var toggler = (
          <button
            type="button"
            class="projecthighlights__expander"
            onClick={(e) => {
              isExpanded = !isExpanded;
              if (isExpanded) {
                toggler.innerHTML = txt.minimizeProjects;
                toggler.title = txt.minimizeProjects;
                toggler.className =
                  'projecthighlights__expander projecthighlights__expander--expanded';
                projecthighlights.classList.add('projecthighlights--expanded');
                startListScroller();
              } else {
                toggler.innerHTML = txt.expandProjects;
                toggler.title = txt.expandProjects;
                toggler.className = 'projecthighlights__expander';
                projecthighlights.classList.remove('projecthighlights--expanded');
              }
              setTimeout(() => {
                (e.target || e.currentTarget).blur();
              }, 100);
            }}
            aria-label={txt.expandProjects}
          >
            {txt.expandProjects}
          </button>
        );

        // FIXME: This could/should be checking the document lanuage (or locaiton.hostname)
        // since `.homepage-no` really means "Norwegian homepage" – no?
        const format = formatMonitor.media.is;
        if (format === 'phone' || format === 'phablet' || q('.homepage-no')) {
            startListScroller();
        }

        projecthighlights.insertBefore(toggler, projecthighlights.children[1]);
    }
};



const initNewsBoxTabbing = () => {
    // FIXME: We really should make this more intelligent -
    // looking for specifically marked boxes that are also ADJACENT
    // to each other in the HTML source.
    // This is fragile, ACK!
    var homepageboxes = qq('.homepage-newsbox, .homepage-bloglist');
    if (homepageboxes.length === 2) {
        homepageboxes.forEach(function (box, i) {
            var boxhead = q('.boxhead', box);
            var boxheadWrapper = <div class="boxhead-wrapper"/>;
            box.insertBefore(boxheadWrapper, boxhead);
            boxheadWrapper.appendChild(boxhead);
            box._boxheadWrapper = boxheadWrapper;
            box.id = box.id || domid();
            box._title = boxhead.textContent;
            box.hidden = i > 0;
        });
        homepageboxes.forEach(function (box, i, boxes) {
            var otherBox = boxes[(i + 1) % 2];
            box.showme = function (focus) {
                otherBox.hidden = true;
                box.hidden = false;
                box.tabIndex = -1;
                focus &&
                    setTimeout(function () {
                        box.focus();
                    }, 0);
            };
            var link = (
              <a
                class="homepagebox__jumplink"
                href={'#' + otherBox.id}
                onClick={(e) => {
                  e.preventDefault();
                  otherBox.showme(focus);
                }}
              >
                {otherBox._title}
              </a>
            );
            box._boxheadWrapper.appendChild(link);
        });
        var outerWrapper = <div class="homepageboxes-wrapper"/>;
        homepageboxes[0].insertAdjacentElement('beforebegin', outerWrapper);
        homepageboxes.forEach(function (box) {
            outerWrapper.appendChild(box);
        });
    }
};


var scrollTo = function (elm, callback) {
    var targetPos =
        (document.body.scrollTop ||
            document.documentElement.scrollTop) +
        elm.getBoundingClientRect().top -
        Math.round(0.04 * window.innerHeight);
    var speed = 800;
    var rate = 16;
    var steps = Math.ceil(speed / rate); // FIXME: <-- This looks weird...
    var step = 0;
    var animId = setInterval(function () {
        var scrollPos =
            document.body.scrollTop ||
            document.documentElement.scrollTop;
        var isLastStep = step === steps - 1;
        if (isLastStep) {
            clearTimeout(animId);
            callback && callback();
        }
        window.scrollTo(
            0,
            scrollPos + (1 / (steps - step)) * (targetPos - scrollPos)
        );
        step++;
    }, rate);
};


const initJumpNav = () => {
    var boxheads = qq('.pgmain .boxhead').filter(function (boxhead) {
        var boxCl = boxhead.parentNode.classList;
        return (
            !boxCl.contains('quickabout') &&
            !boxCl.contains('projecthighlights') &&
            // !boxCl.contains('homepage-bloglist')
            true
        );
    });
    if (boxheads.length > 0) {
        var quickabout = qq('.quickabout')[0];
        if (quickabout) {
            const bem = lang === 'no' ? 'simplejumpnav' : 'stackedjumpnav';

            var jumpnav = <div class={bem} />;

            boxheads.forEach(function (boxhead) {
                var box = boxhead.parentNode;
                if (box.classList.contains('boxhead-wrapper')) {
                    box = box.parentNode;
                }
                var boxClass = box.className.split(' ').pop();
                var boxid = box.id;
                if (!boxid) {
                    boxid = boxClass + domid();
                    box.id = boxid;
                }
                var linkText = (qq('.boxhead', box)[0] || {})
                  .textContent;
                var link = (
                  <a
                    class={bem + '__link ' + bem + '__link--' + boxClass}
                    href={'#' + boxid}
                    onClick={(e) => {
                      e.preventDefault();
                      if (box.showme) {
                        box.showme();
                      }
                      box.tabIndex = -1;
                      scrollTo(box, () => {
                        box.focus();
                      });
                    }}
                  >
                    {linkText}
                  </a>
                );
                jumpnav.appendChild(link);
            });

            quickabout.appendChild(jumpnav);
        }
    }
};


const initBlogListItems = () => {
  // FIXME: Solve at least parts of this in CSS only... Ack!
  const blogList = q('.homepage-bloglist');
  if (blogList) {
    const blogItmAuthors = qq('.homepage-bloglist__contact', blogList);
    if (blogItmAuthors.length) {
      q('.boxbody .item', blogList).insertAdjacentElement(
        'afterend',
        <div class="item itm2">{blogItmAuthors}</div>
      );
    }
    const moreLink = q('.more', blogList);
    if (moreLink) {
      q('.moreolder', blogList).insertAdjacentElement('afterbegin', moreLink);
    }
  }
};



export default () => {
    loadScript('https://eplica-cdn.is/req/jqreq.js', function () {
        window.Req(
            'jquery',
            'listscroller',

            function () {
                initTopPanel();
                initNewsBoxTabbing();
                if (!window.EPLICA_loggedin) {
                    initJumpNav();
                }
                initBlogListItems();
                document.documentElement.classList.remove('beforejsinit-home'); //remove flicker trick
            }
        );
    });
};
