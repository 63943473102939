const formatGroups = {
  StickyHeader: { phone: 1, phablet: 1 },
  Large: { netbook: 1, wide: 1, huge: 1 },
  MobileMenu: { phone: 1, phablet: 1, tablet: 1 },
};

if (document.documentElement.classList.contains('home')) {
  formatGroups.StickyHeader.tablet = 1;
  formatGroups.MobileMenu.netbook = 1;
  formatGroups.MobileMenu.wide = 1;
  formatGroups.MobileMenu.huge = 1;
}

export default formatGroups;
